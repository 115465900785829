@import '~antd/dist/antd.css';

.App {
  background: #e5e5e5;
  font-family: 'GT Walsheim';
}

@media screen and (min-width: 1500px) {
  .App {
    font-family: 'GT Walsheim';
  }
}

body{
  overflow-x: hidden;
}