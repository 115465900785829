.privacySection {
  padding: 10rem 4em;
  font-family: 'GT Walsheim';
  font: bolder;
  background-color: #f4f2fb;
}

.headingtext {
  color: #1c0745;
  font-size: 48px;
  font-weight: 800;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
}

.headertexts {
  color: #1c0745;
  font-size: 18px;
  font-weight: 800;
  line-height: 26px;
  letter-spacing: 0.6px;
  text-align: left;
  margin-top: 1rem;
}

.titletext {
  color: #504e5c;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.listItems {
  padding-left: 1rem;
  display: block;
  list-style: circle;
  color: black;
}
