
.billImage {
  height: 3rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.billTitle {
  font-weight: 500;
  font-family: 'GT Walsheim Bold';
  font-size: 1.5rem;
  margin-top: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pinkBrushStroke {
  position: absolute;
  z-index: 2;
}

.service {
  padding-bottom: 75px;
}

.section1 {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.section1 .subTitle {
  font-family: 'GT Walsheim';
  font-size: 1.2rem;
  font-weight: normal;
  margin-top: 1rem;
  margin-bottom: 75px;
}