.section {
  background: #1c0745;
  padding: 4em;
  margin-top: 0rem;
}

.section5Content {
  color: white;
  margin: 2rem 0rem 0rem 0rem;
}

.mobile-app-title {
  margin: 2rem 0rem 0rem 0rem;
  font-family: 'GT Walsheim Bold';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.mobile-app-subTitle {
  font-family: 'GT Walsheim Bold';
  margin: 1rem 0rem 0rem 0rem;
  font-size: 2.5rem;
  line-height: 3.375rem;
}

.mobile-app-description {
  font-family: 'GT Walsheim';
  margin: 1rem 0rem 0rem 0rem;
  font-size: 1.2rem;
  line-height: 2rem;
}

.vbtnalignment {
  margin: 3rem 0rem 0rem 0rem;
}

.bannerImage {
  width: 70%;
}

/* Ordered Media Query Added For Handling Responsive Elements */

@media screen and (min-width: 300px) and (max-width: 670px) {
  .linkIcons {
    width: 90%;
  }
  .bannerImage {
    width: -webkit-fill-available;
  }
  .headertextimage {
    width: -webkit-fill-available;
  }
}

@media screen and (min-width: 700px) {
  .linkIcons {
    width: 90%;
  }
  /* .bannerImage {
    width: -webkit-fill-available;
  } */
  .headertextimage {
    width: -webkit-fill-available;
  }
}

@media screen and (min-width: 1022px) {
  .linkIcons {
    width: 80%;
  }
  .headertextimage {
    width: 70%;
  }
}

@media screen and (min-width: 1436px) {
  .linkIcons {
    width: 70%;
  }
}

@media screen and (min-width: 2500px) {
  .linkIcons {
    width: 60%;
  }

  .vheadingalignment {
    margin: 6rem 0rem 0rem 0rem;
    font-size: 41px;
  }

  .vheadinglightalignment {
    margin: 3rem 0rem 0rem 0rem;
    font-size: 31px;
  }
}

