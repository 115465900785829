/* .pinkLine {
} */

.blueCross {
  margin-bottom: -90px;
  padding-right: 300px;
}

.pinkLine {
  padding-bottom: 60px;
} 

.section2 {
  margin-top: 10rem;
}

.section2-title {
  font-weight: bold;
  font-size: 2.5rem;
  font-family: 'GT Walsheim Bold';
  color: #1C0745;
  text-align: center;
  padding: 0px;
  /* margin: 1em 0; */
}

.functions {
  background-color: #FAF9FE;
  padding-top: 0;
  padding-bottom: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 40px;
  background: rgba(248, 247, 249, 0.5);
  opacity: 0.9;
  box-shadow: 33.1009px 33.1009px 110.336px rgba(109, 125, 182, 0.11);
  backdrop-filter: blur(16px);
}

.functionTitle {
  font-weight: bold;
  font-family: 'GT Walsheim Bold';
  font-size: 1.375rem;
  text-align: center;
}

.functionDescription {
  font-family: 'GT Walsheim';
  font-size: 1.2rem;
  text-align: center;
}

.functionBox {
  /* padding: 0px 50px; */
}

.functionBox img {
  height: 10rem;
  align-self: center;
}

.functionBox .functionImage {
  height: 10rem;
}