
.dark {
  color: #1C0745 !important;
}

.light {
  color: white !important;
}

.headerButtons h2{
  font-family: 'GT Walsheim Bold';
  font-size: 1.2rem;
  margin: 2rem;
  font-weight: bold;
  background-color: none;
  cursor: pointer;
}

a:hover {
  color: white;
}

.logoImage {
  height: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.header {
  padding: 1em;
  padding-top: 250px;
  background-color: #2F1464;
  padding-bottom: 200px;
  /* background-image: url('../../assets/backgroundImages/mainBg.png'); */
  backdrop-filter: blur(4px);
}

.topBar {
  position: fixed;
  width: 100%;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2000;
  margin: 0px;
}

.topBar.light {
  background-color: #2F1464;
}

.topBar.dark {
  background-color: #f4f2fb;
}

.headerGetStartBtn:hover {
  background: #f072cc;
}

.headerGetStartBtn > span {
  color: white;
  font-style: normal;
  letter-spacing: 0em;
  text-align: center;
}