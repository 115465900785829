body {
  margin: 0;
  font-family: 'Articulat CF';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url(./assets/fonts/GT-Walsheim-Regular.ttf);
}

@font-face {
  font-family: 'GT Walsheim Bold';
  src: url(./assets/fonts/GT-Walsheim-Bold.ttf);
}
