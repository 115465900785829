.section6 {
  margin-top: 100px;
  margin-bottom: 50px;
}

.section6 h4 {
  font-family: 'GT Walsheim Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3.375rem;
  text-align: center;
  color: #1C0745;
  z-index: 100;
  background: url('../../../assets/images/pink_scribble.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
}

.section6  h5 {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-align: center;
  color: #504E5C;
}

.section6 .faqList {
  margin-top: 50px;
  background-color: #f4f2fb;
}

.section6 .faqList .question {
  font-family: 'GT Walsheim Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #1C0745;
  border-bottom:  1px solid #D1CAEA;
}

.section6 .faqList .question .answer {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #504E5C;
  margin-bottom: 20px;
}

.section6 .faqList .question .answer .eachAnswer {
  margin-top: 20px;
}

.eachSecondaryAnswer {
  font-size: 14px;
  margin-top: 10px;
  font-style: italic;
}