.modalAlignRow {
  width: 500px;
  border-radius: 20%;
  text-align: center;
  font-family: 'GT Walsheim';
}

.justifyTextCenter {
  text-align: center;
  justify-content: center;
  margin: 1rem 0rem 0rem 1rem;
}

.subtexts {
  text-align: center;
  justify-content: center;
  /* margin: 1rem 0rem 0rem 1rem; */
}

.heading {
  font-size: 40px;
  font-weight: bolder;
  line-height: 54px;
}

.successBtn {
  background: #7a60e7;
  height: 48px;
  width: 280px;
  color: white;
  border-radius: 20px;
  font-size: 16px;
  line-height: 18px;
}

.successBtn:hover {
  background: #7a60e7;
  height: 48px;
  width: 280px;
  color: white;
  border-radius: 20px;
  font-size: 16px;
  line-height: 18px;
}

.ant-modal-content {
  border-radius: 16px !important;
}
