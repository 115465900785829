.sectionBg {
  padding: 4em;
  background-image: url('../../../assets/backgroundImages/privacybg.png');
  height: 810px;
  color: white;
  position: relative;
  z-index: 10;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.brushBg {
  position: absolute;
  top: 3rem;
  right: 15rem;
  z-index: 2;
}

.learnBtn {
  background: #1c0745;
  height: 3rem;
  width: 10rem;
  border-radius: 19px;
  border: 1px solid #1c0745;
  margin-top: 1rem;
  margin-right: 2rem;
}

.title-privacy {
  font-family: 'GT Walsheim Bold';
  font-size: 1.375rem;
  font-style: normal;
  font-weight: bold;
  line-height: 1.5rem;
  letter-spacing: 1px;
}

.our-priority {
  padding-left: 1rem;
}

.dark-blue-scribble {
  position: absolute;
  margin-top: -20px;
  z-index: -1;
}

.subTitle-privacy {
  font-family: 'GT Walsheim Bold';
  font-size: 2.5rem;
  font-style: normal;
  font-weight: bold;
  line-height: 3.375rem;
  letter-spacing: 1px;
  margin-top: 1rem;
  margin-bottom: 1.3rem;
}

.description-privacy {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 4rem;
}