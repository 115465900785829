.tcSection {
    padding: 4em;
    padding-top: 10em;
    height: auto;
}

.itemlists {
    padding: 1rem 0rem 0rem 0rem;
}

.aligntext {
    margin: 1.1em 0em 0em 0.4em;
}

.tcSection a {
    text-decoration: underline;
}

.tcSection a:hover {
    color: #1890ff;
}