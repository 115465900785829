.features {
  background-color: #f4f4fb;
  width: 100%;
  /* padding: 4em */
}

.title {
  font-weight: bold;
  font-size: 2.5rem;
  font-family: 'GT Walsheim Bold';
  color: #1c0745;
  text-align: center;
  margin: 1em 0;
}

.title > .text {
  position: inherit;
  z-index: 4;
}
