.aylaHeadingContentRow {
  color: white;
  justify-content: center;
  margin: 1rem 0rem 0rem 0rem;
}
.homeBg {
  background-color: #2f1464;
  padding: 4em;
}
.aylaPhoneRow {
  justify-content: center;
  margin: 16rem 0rem 0rem 0rem;
}
.homeHeadingContent {
  color: white;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 2rem;
}
.curve {
  background-color: #f4f2fb;
  width: 100vw;
}
.curve img {
  width: 100vw;
}
.main-title {
  font-family: 'GT Walsheim Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 4rem;
  text-align: center;
  color: #f4f2fb;
  margin: 2rem 0;
}
.main-title .pink {
  color: #fe8edf;
}
.main-subTitle {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2.275rem;
  color: #f4f2fb;
  text-align: center;
}
.mainheadertext {
  width: fit-content;
}
.downloadLinks {
  padding-top: 2rem;
}

.whiite-arrow-hero {
  margin-left: 1rem;
}

.phoneInputWrapper {
  margin-left: 9rem;
}
.container {
  background:
    url('../../assets/backgroundImages/aylaVector.png') no-repeat center,
    url('../../assets/backgroundImages/bgtopleft.png') 10vw 15vh no-repeat,
    url('../../assets/backgroundImages/bgtopcenter.png') center 10vh no-repeat,
    url('../../assets/backgroundImages/bgmiddleleft.png') 5vw 30vh no-repeat,
    url('../../assets/backgroundImages/bgmiddleright.png') 80vw 30vh no-repeat,
    url('../../assets/backgroundImages/bgtopright.png') 75vw 15vh no-repeat,
    url('../../assets/backgroundImages/bgbottomright.png') 75vw bottom no-repeat,
    url('../../assets/backgroundImages/bgbottomleft.png') 10vw bottom no-repeat;
  background-size: 25%, 70px, 100px, 80px, 100px, 100px, 100px, 100px;
}
.container {
  padding-top: 6rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 1rem;
  background-color: #2f1464;
}
@media screen and (min-width: 300px) and (max-width: 670px) {
  .mainheadertext {
    width: 109%;
  }
  .home {
    padding: 1rem;
  }
}
@media screen and (min-width: 700px) {
  .aylaMainContentImage {
    margin: 5rem 0rem 0rem 3rem;
    width: 87%;
  }
  .home {
    padding: 1rem;
  }
}
@media screen and (min-width: 1022px) {
  .aylaMainContentImage {
    margin: 3rem 0rem 0rem -2rem;
    width: 111%;
  }
  .homeHeadingContent {
    margin: 1rem 3rem 0rem 3rem;
  }
  .home {
    padding: 2rem;
  }
}
@media screen and (min-width: 2500px) {
  .home {
    margin: 3rem 0rem 0rem 0rem;
    background-size: 50%, 60px, 70px, 70px, 70px, 70px, 70px, 70px;
    padding: 3rem;
  }
  .aylaMainContentImage {
    margin: 6rem 0rem 0rem -6rem;
    width: 150%;
  }
  .homeHeadingContent {
    margin: 1rem 0rem 0rem 0rem;
  }
}

@media screen and (max-width: 992px){
  .whiite-arrow-hero {
    margin-left: 0rem;
    display: none;
  }
  
  .phoneInputWrapper {
    margin-left: 0rem;
  }
}