.section3 {
  margin: 100px auto;
}

.section3-title {
  font-family: 'GT Walsheim Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #FE8EDF;
  margin: 1.5rem 0.5rem;
}

/* .section3-control {
  background: url('../../../assets/images/green_cross.png') no-repeat;
}

.section3-goodbye {
  background: url('../../../assets/images/pink_scratch.png') no-repeat;
  background-position-y: 21px ;
} */

.section3-subTitle {
  font-family: 'GT Walsheim Bold';
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3.38rem;
  color: #1C0745;
  margin: 1rem 0.5rem;
}

.section3-description {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #504E5C;
  margin: 1rem 0.5rem;
}

.section3-individual {
  margin: 8vw 0;
}

.section3-image img {
  width: 30vw;
}

.content {
  margin-top: 4rem;
}

@media only screen and (max-width: 1600px) {
  .section3-image img{
    width: 40vw;
  }
}

@media only screen and (max-width: 1200px) {
  .section3-image img{
    width: 40vw;
  }
}

@media only screen and (max-width: 992px) {
  .section3-image img{
    width: 50vw;
  }
  .section3-individual {
    margin: 5vw 0;
  }
}

@media only screen and (max-width: 768px) {
  .section3-image img{
    width: 70vw;
  } 
  .section3-individual {
    margin: 2vw 0;
  }
}

@media only screen and (max-width: 576px) {
  .section3-image img{
    width: 40vw;
  }
  .section3-individual {
    margin: 1vw 0;
  }
}
