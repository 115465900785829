.mainFooter {
  color: white;
}

.footer {
  background: #140929;
  padding: 4em;
}
h3 {
  color: white;
  font-family: 'GT Walsheim';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-align: left;
  cursor: pointer;
}

.footerlastrow {
  color: #898494;
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  padding-top: 40px;
}

.linkedInIcon {
  /* width: 22px; */
  background: white;
  color: black;
  font-size: 32px;
}
