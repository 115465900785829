.section4 {
  padding: 200px 0;
  background:
    url('../../../assets/images/ellipse_899.png') 0vw top no-repeat,
    url('../../../assets/images/ellipse_900.png') 100vw center no-repeat;

}

.section4  h2 {
  font-family: 'GT Walsheim Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 24px;
  text-align: center;
  color: #FE8EDF;
}

.section4  h4 {
  font-family: 'GT Walsheim Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.375rem;
  text-align: center;
  color: #1C0745;
  background: url('../../../assets/images/pink_spring.png');
  padding: 2rem 0;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.review {
  position: relative;
  background: #FDFDFF;
  /* margin: 20px; */
  border-radius: 20px;
  box-shadow: -8px 0px 60px rgba(109, 125, 182, 0.05), 8px 8px 60px rgba(109, 125, 182, 0.05);
  width: 300px;
  height: 350px;
  margin: auto;
  padding: 1rem;
  margin-top: 100px;
  margin-bottom: 50px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.review .review-name {
  font-family: 'GT Walsheim';
  margin-top: 37.5px;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #504E5C;
}

.review .review-title {
  font-family: 'GT Walsheim Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.625rem;
  color: #1C0745;
  text-align: center;
  margin-top: 0.5rem;
  /* letter-spacing: 0rem; */
  margin-bottom: 2rem;
  display: inline-block;
  word-wrap: break-word;
}

.review .review-description {
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #504E5C;
  text-align: center;
}

.review .review-pic {
  position: absolute;
  /* box-shadow: -8px 0px 60px rgba(109, 125, 182, 0.05), 8px 8px 60px rgba(109, 125, 182, 0.05); */
  background: #FDFDFF;
  border-radius: 50%;
  top: -50px;
  left: 100px;
}

.review .review-star {
  position: absolute;
  left: 86px;
  /* top: 300px; */
  bottom: 20px;
  
}