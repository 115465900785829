.phoneInput[type='number'] {
  -moz-appearance: textfield;
}

.australiaFlag {
  height: 1rem;
  margin: 1rem;
}

.getStarted {
  background: #f072cc;
  font-size: 1rem;
  font-weight: 700;
  font-family: 'GT Walsheim';
  color: white;
  border-radius: 22px;
  padding: 1rem 0.8rem;
  cursor: pointer;
}

.phoneInput {
  background-color: #cec4f9;
  /* flex: 1 1; */
  min-width: 0;
  font-size: 1rem;
  border: none;
  background: none;
  font-family: 'GT Walsheim';
  color: white;
  outline: none;
  /* margin-top: 1rem; */
}

.plus61 {
  color: white;
  font-family: 'GT Walsheim';
  font-size: 1rem;
  /* margin-right: 1rem;
  vertical-align: center; */
  margin-top: 1rem;
}

.phoneInputRow {
  /* background: linear-gradient(#2f1464, #5a3e91); */
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #CEC4F9;
  box-sizing: border-box;
  backdrop-filter: blur(8px);
/* Note: backdrop-filter has minimal browser support */

  border-radius: 22px;
  margin: 4rem 0rem 0rem 0rem;
  width: max-content;
}

.get-started {
  margin-top: 1rem;
  width: 100%;
  padding: 0.5rem;
}

@media screen and (min-width: 325px) and (max-width: 700px) {
  .getStarted {
    background: #f072cc;
    border-radius: 80px;
    font-size: 1.4rem;
    font-weight: 700;
    font-family: 'GT Walsheim';
    color: white;
    padding: 0.5rem 2.5rem;
  }
}
